<template>
  <div class="container">
    <div class="login-container">
      <el-form ref="ruleForm2" :model="ruleForm2" :rules="rules2" label-position="right" label-width="90px" status-icon>
        <img src="../../assets/login_logo.png" style="width: 60px;height: auto;object-fit: cover;">
        <el-form-item prop="email" label="Email:">
          <el-input v-model="ruleForm2.email" type="text" auto-complete="off" placeholder="Email" />
        </el-form-item>
        <el-form-item prop="password" label="Password:">
          <el-input v-model="ruleForm2.password" auto-complete="off" placeholder="Password" type="password" />
          <!-- <p style="text-align: right;text-decoration: underline;cursor: pointer;"
            @click="showResetPasswordDialog = true">忘记密码</p> -->
        </el-form-item>
      </el-form>
      <div style="display: flex;flex-direction: column;align-items: center;margin-top:0px;">
        <p class="common-btn" @click="submit">Login</p>
        <p class="common-btn" @click="doRegister" style="margin-top: 20px;background-color: #F7B52B;color: #333;">
          Register</p>
      </div>
    </div>
    <el-dialog :visible.sync="showResetPasswordDialog" width="600px" :modal="true" title="Reset password"
      :modal-append-to-body="true">
      <div class="customDialogContent">
        <el-form ref="resetPwdForm" :model="resetPwdForm" :rules="rules1" label-position="right" label-width="150px"
          status-icon>
          <el-form-item prop="email" label="Email:">
            <el-input v-model="resetPwdForm.email" type="text" auto-complete="off" placeholder="Email" />
          </el-form-item>
          <el-form-item prop="password" label="Password:">
            <el-input v-model="resetPwdForm.password" auto-complete="off" placeholder="Password" type="password" />
          </el-form-item>
          <el-form-item prop="confirm_pwd" label="Confirm Password:">
            <el-input v-model="resetPwdForm.confirm_pwd" auto-complete="off" placeholder="Password" type="password" />
          </el-form-item>
        </el-form>
        <div style="display: flex;flex-direction: column;align-items: center;margin-top: 10px;">
          <p class="common-btn" @click="doResetPwd">Confirm</p>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  resetPassword,
  loginByStudent
} from '../../api/index'
import { setUserId, setUserAccountCountry, setUserName } from '../../utils/store'
export default {
  name: 'Login',
  data() {
    return {
      logining: false,
      ruleForm2: {
        email: '',
        password: ''
      },
      resetPwdForm: {
        email: '',
        password: '',
        confirm_pwd: ''
      },
      showResetPasswordDialog: false,
      rules2: {
        email: [
          { required: true, message: 'Please enter your email', trigger: 'blur' }
        ],
        password: [
          { required: true, message: 'Please enter your password', trigger: 'blur' }
        ]
      },
      rules1: {
        email: [
          { required: true, message: 'Please enter your email', trigger: 'blur' }
        ],
        password: [
          { required: true, message: 'Please enter your password', trigger: 'blur' }
        ],
        confirm_pwd: [
          { required: true, message: 'Please enter your password', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {

    doRegister() {
      this.$router.push({
        path: '/register'
      })
    },

    doResetPwd() {
      this.$refs.resetPwdForm.validate((val) => {
        if (val) {
          if (this.resetPwdForm.password != this.resetPwdForm.confirm_pwd) {
            this.$message.warning('两次密码不一致~')
            return
          }
          resetPassword(this.resetPwdForm.email, this.resetPwdForm.password).then((res) => {
            let result = res.data
            if (result.code == 0) {
              this.$message.success('Reset success')
              this.showResetPasswordDialog = false
            } else {
              this.$message.error(result.msg)
            }
          })
        }
      })
    },


    submit() {
      this.$refs.ruleForm2.validate((val) => {
        if (val) {
          loginByStudent(this.ruleForm2.email, this.ruleForm2.password).then((res) => {
            let result = res.data
            if (result.code == 0) {
              setUserId(result.data._id.$id)
              let student_acountry = "China";
              if (result.data.student_acountry) {
                student_acountry = result.data.student_acountry;
              }
              setUserAccountCountry(student_acountry);
              const user_name =
                result.data.student_givenName_pinyin + ' ' + result.data.student_lastName_pinyin;
              setUserName(user_name);
              this.$UserData.user_name = user_name;
              this.$UserData.account_country = student_acountry;
              this.$message.success('Login success')
              this.$router.replace('/home')
            } else {
              this.$message.error(result.msg)
            }
          })
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.customDialogContent {
  padding: 0 24px;
  box-sizing: border-box;
  width: 100%;
  background: #ffffff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}


.container {
  width: 100vw;
  height: 100vh;
  // background-color: #50D1D7;
  background: url('../../assets/loginBg.jpg');
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  .login-container {
    margin-top: 150px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    -moz-border-radius: 5px;
    width: 370px;
    padding: 25px 25px 20px;
    background: #fff;
    border: 1px solid #eaeaea;
    box-shadow: 0 0 25px #cac6c6;
  }

  .common-btn {
    cursor: pointer;
    font-family: GravityBold;
    text-align: center;
    line-height: 38px;
    height: 38px;
    border-radius: 5px;
    width: 65%;
    color: white;
    font-size: 16px;
    background-color: var(--c-primary);
  }
}
</style>
